import { render, staticRenderFns } from "./PaginaPrincipal.vue?vue&type=template&id=8abceb8e&"
import script from "./PaginaPrincipal.js?vue&type=script&lang=js&"
export * from "./PaginaPrincipal.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./PaginaPrincipal.css?vue&type=custom&index=0&blockType=link&issuerPath=%2Fhome%2Fvsts%2Fwork%2F1%2Fs%2FESF.Portal.FrontEnd%2Fsrc%2Fviews%2Fpublic%2FPaginaPrincipal.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports