export default {
  name: "PaginaPrincipal",

  data() {
    return {
      lstAllConcepts: [
        {
          Title: "Radicación",
          Contents: [
            "<b>Recibir y Validar</b> los datos (estructurados) y sus documentos relacionados",
            "<b>Iniciar los Procesos</b> desde una ventanilla de radicación web para los datos y los documentos a procesar",
            "<b>Registro masivo a medida</b> desde una aplicación web a la medida reciba los datos y archivos"
          ]
        }, {
          Title: "Publicación",
          Contents: [
            "<b>Con Excel y los Archivos</b> da inicio a la carga de un catalogo documental via web o con un publicador",
            "<b>Los usuarios pueden publicar sus Documentos</b> de forma masiva asociandolos por Sticker único",
            "<b>Validar los Datos y Documentos</b> antes de su publicación para mejorar la calidad de estos"
          ]
        },
        {
          Title: "Procesos",
          Contents: [
            "<b>Definición</b> de los procesos para determinar como participan las personas, los datos y las aplicaciones",
            "<b>Integrar via APIs web</b> las operaciones para hacer el proceso con las aplicaciones existentes",
            "<b>Desarrollo a Medida</b> de aplicaciones que cubran las necesidades de integración o soporte"
          ]
        }, {
          Title: "Consultas y Gestión",
          Contents: [
            "<b>Seguimiento, Gestión y Consultas</b> del estado de sus datos y documentos segun sus requerimientos (reglas de negocio)",
            "<b>Monitoreo continuo</b> de la disponibilidad y desempeño de las aplicaciones"
          ]
        }, {
          Title: "Mesa de Ayuda",
          Contents: [
            "<b>Soporte 7x24</b> con escalamiento a diferente niveles con personas especializadas y expertas para dar atención a sus requerimientos",
            "<b>Canales de Atención a Medida</b> para el soporte según los temas y proyectos"
          ]
        }, {
          Title: "Digitalización",
          Contents: [
            "<b>Con escaners Fujitsu de alta capacidad</b> y la Aplicación de Escaneo se soporta la conversión a PDF o TIFF",
            "<b>Transformaciones a Medida</b> con exportación y soporte de tipificación a formatos PDF/A y TIFF"
          ]
        }
      ]
    };
  },

  methods: {
  },

  mounted() {    
    document.getElementById('imgISO').setAttribute('src', Settings.StaticURL + '/Images/PysISO.png');
    document.getElementById('imgMicrosoftPartner').setAttribute('src', Settings.StaticURL + '/Images/PysEsfSilverMicrosoft.gif');
  }

};